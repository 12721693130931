@font-face {
  font-family: 'talents-view-icons';
  src:  url('fonts/talents-view-icons.eot?9h2gjg');
  src:  url('fonts/talents-view-icons.eot?9h2gjg#iefix') format('embedded-opentype'),
    url('fonts/talents-view-icons.woff2?9h2gjg') format('woff2'),
    url('fonts/talents-view-icons.ttf?9h2gjg') format('truetype'),
    url('fonts/talents-view-icons.woff?9h2gjg') format('woff'),
    url('fonts/talents-view-icons.svg?9h2gjg#talents-view-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'talents-view-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-picture:before {
  content: "\e91f";
}
.icon-bell:before {
  content: "\e91e";
}
.icon-tel:before {
  content: "\e91d";
}
.icon-eye-regular:before {
  content: "\e91b";
}
.icon-eye-slash-regular:before {
  content: "\e91c";
}
.icon-add:before {
  content: "\e91a";
}
.icon-filter-line:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e918";
}
.icon-trash:before {
  content: "\e917";
}
.icon-arrow-light-up:before {
  content: "\e911";
}
.icon-arrow-light-right:before {
  content: "\e914";
}
.icon-arrow-light-down:before {
  content: "\e915";
}
.icon-arrow-light-left:before {
  content: "\e916";
}
.icon-Groupe-157:before {
  content: "\e90a";
}
.icon-arrow-line-left:before {
  content: "\e900";
}
.icon-arrow-line-right:before {
  content: "\e901";
}
.icon-arrow-line-up:before {
  content: "\e902";
}
.icon-arrow-line-down:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-mobile:before {
  content: "\e908";
}
.icon-pdf:before {
  content: "\e909";
}
.icon-icon_Fichier-17:before {
  content: "\e90b";
}
.icon-params:before {
  content: "\e90c";
}
.icon-mail:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-skill:before {
  content: "\e90f";
}
.icon-data:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
