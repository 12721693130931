@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --cera-light: "Cera light", sans-serif;
  --cera-medium: "Cera medium", sans-serif;
  --cera-bold: "Cera bold", sans-serif;
  --cera-black: "Cera black", sans-serif;
  --avenir-light: "Avenir Light", sans-serif;
  --avenir-medium: "Avenir Medium", sans-serif;
  --avenir-black: "Avenir Black", sans-serif;
  --talent-icons: "talent-icons", sans-serif;
}

@font-face {
  font-family: "talent-icons";
  src: url("./fonts/talent-icons.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Cera light";
  src: url("./fonts/Cera-Pro-Light.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Cera regular";
  src: url("./fonts/Cera-Pro-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Cera medium";
  src: url("./fonts/Cera-Pro-Medium.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Cera bold";
  src: url("./fonts/Cera-Pro-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Cera black";
  src: url("./fonts/Cera-Pro-Black.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Avenir Light";
  src: url("./fonts/Avenir-Light.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Avenir Medium";
  src: url("./fonts/Avenir-Medium.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Avenir Black";
  src: url("./fonts/Avenir-Black.otf") format("opentype");
  font-style: normal;
}

.cera-font {
  font-family: "Cera regular";
}

.cera-light-font {
  font-family: "Cera light";
}
.cera-medium-font {
  font-family: "Cera medium";
}
.cera-bold-font {
  font-family: "Cera bold";
}
.cera-black-font {
  font-family: "Cera black";
}
.avenir-light-font {
  font-family: "Avenir Light";
}
.avenir-medium-font {
  font-family: "Avenir Medium";
}
.avenir-black-font {
  font-family: "Avenir Black";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
